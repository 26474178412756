import React, {useState} from 'react';
import {PollChoiceModel} from "../../models/PollChoiceModel";

import classNames from "classnames";
import "./Poll.css";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    pushed : {
        backgroundColor: "#1E48BB",
    }
}))

const PollSubmit = ({selectedChoice, onSubmitted}: {selectedChoice: PollChoiceModel, onSubmitted: Function}) => {

    const [submitting, setSubmitting] = useState(false);

    const styles = useStyles({});
    const [isDown, setIsDown] = useState(false);

    const pollSubmitClasses = classNames({
        "poll-submit-container": true,
        "poll-submit-container-enabled": selectedChoice !== null,
        "poll-submit-container-submitting": submitting,
    }, isDown ? styles.pushed : "")

    const submitChoice = () => {
        onSubmitted(selectedChoice);
    }


    return (
        <div className={pollSubmitClasses} onClick={submitChoice}  onMouseDown={() => setIsDown(true)}>
            <div className="poll-submit">
                { submitting ? 'Saving...' : 'Next'}
            </div>
        </div>
    )
}

export default PollSubmit;
