import React from 'react';
import Settings from "../../components/Settings/Settings";
import PollHeader from "../../components/Poll/PollHeader";
import DefaultHeader from "../../components/Headers/DefaultHeader";


const SettingsScreen = ({history, invalidateUser}) => {


    return (
        <div>
            <DefaultHeader label="Settings" showBack={true} goBack={() => history.goBack()}/>
            <Settings onLogout={() => invalidateUser(false)}/>
        </div>
    )
}

export default SettingsScreen;