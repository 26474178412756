import React, {useContext, useState} from "react";
import {PollResponseModel} from "../../models/PollResponseModel";

import {Grid, Typography, Button, makeStyles} from '@material-ui/core'
import AvatarThumbnail from "../Images/AvatarThumbnail";
import {AppContext} from "../../context/AppContext";
import {AvatarizeName} from "../../services/Utilities";

import thumbsup from "../../assets/images/thumbsup-xs.png";
import addFriend from "../../assets/images/addfriend-xs.png";
import IconObject from "../Images/IconObject";
import {followUser, unfollowUser} from "../../services/FollowerService";

const useStyles = makeStyles(theme => ({
    responseContainer : {

        display: 'flex',
        alignItems: 'center',
    }
}));

const PollResponse = ({response}: {response: PollResponseModel}) => {
    const styles = useStyles({});
    const context = useContext(AppContext);

    const [reload, setReload] = useState(false);

    const unfollow = async () => {
        await unfollowUser(context.token, response.ProfileId);
        // we should toggle it off to load later...
        response.FollowingThem = false;
        setReload(!reload);
    };

    const follow = async () => {
        await followUser(context.token, response.ProfileId);
        // we should toggle it off to load later...
        response.FollowingThem = true;
        setReload(!reload);
    };

    const renderFollowerButton = () => {
        if (response.ProfileId === context.profile.ProfileId) {
            return ""
        } else if (response.FollowingThem) {
            return (
                <Button variant="contained" color="secondary" onClick={unfollow}>
                    <IconObject urlObject={thumbsup}/> Friends
                </Button>
            );
        } else {
            return <Button variant="contained" color="primary" onClick={follow}>
                <IconObject urlObject={addFriend}/> Reader
            </Button>
        }
    }

    const responseInlineStyle = {
        color: response.ChartColour,
        marginRight:5,
        fontSize:16,
        fontWeight: 300,
    }

    return (
        <Grid container>
            <Grid item xs={1}   className={styles.responseContainer}>
                <AvatarThumbnail url={response.AvatarMicrothumbUrl} alt={AvatarizeName(response.DisplayName)} width={30} radius={30}/>
            </Grid>
            <Grid item xs={4}  className={styles.responseContainer}>
                <Typography variant="h6" align="left">
                    {response.DisplayName}
                </Typography>
            </Grid>
            <Grid item xs={4} className={styles.responseContainer}>
                <Typography variant="h6" align="left" style={responseInlineStyle}>
                    {response.Choice}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                {renderFollowerButton()}

            </Grid>
        </Grid>
    )
}

export default PollResponse