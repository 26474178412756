import React, {useContext, useEffect, useState} from "react";
import { Redirect } from "react-router-dom";
import {getPoll, submitStandardPoll} from "../../services/PollsService";
import "./PollScreen.css"
import PollChoices from "../../components/Poll/PollChoices";
import {PollQuestionModel} from "../../models/PollQuestionModel";
import {AppContext} from "../../context/AppContext";
import PollSubmit from "../../components/Poll/PollSubmit";
import {makeStyles} from "@material-ui/core";
import {Visibility} from "../../models/VisibilityEnum";

import {PollContext} from "../../context/PollContext";
import PollHeader from "../../components/Poll/PollHeader";
import getLocation from "../../services/LocationService";

const useStyles = makeStyles(theme =>  ({
    rightAlign: {
        marginLeft: "auto"
    },
    pollChoiceContainer: {
        height:"100%",
        display:'flex',
        flexDirection:'column',
    },
    submitContainer: {
        marginTop:10,
        display:'flex',
        justifyContent: 'flex-end',
    }
}))

export default function PollScreen({history}) {
    const styles = useStyles({});
    const {choices, polls, updateCurrentPoll, pollIndex} = useContext(PollContext);
    const {token}  = useContext(AppContext);

    const [question, setQuestion]: [PollQuestionModel, Function] = useState(null);
    const [pollChoices, setPollChoices] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState(null);

    const selectChoice = (choice) => {
        setSelectedChoice(choice);
    };

    const submitAnswer = async (choice) => {
        const coords = await getLocation();
        const data = await submitStandardPoll(token, question.PollId, Visibility.Friends, choice.PollChoiceId, coords.coords.latitude, coords.coords.longitude);

        if (data.Success) {
            const {Question, Choices} = await getPoll(token, question.PollId);

            setPollChoices(Choices);
            setQuestion(Question);
        }
    };

    const previousPoll = () => {
        let newPollIndex = pollIndex-1;
        updateCurrentPoll({currentPoll: polls[newPollIndex], pollIndex:newPollIndex, choices: null});
        history.goBack();
    };

    useEffect(() => {

        const getQuestion = async () => {
            const {Choices, Question} = await getPoll(token, polls[pollIndex].PollId);
            setQuestion(Question);
            setPollChoices(Choices);
            updateCurrentPoll({currentPoll:Question, pollIndex, choices});
            setLoaded(true);
        }
        if (!question && token) {
            getQuestion();
        }
    }, [token]);

    function showPoll() {
        //If we can answer, we should go to the Results Screen for this Poll...
        if (!question.CanAnswer) {
            //pollChoices here should be newest one?
            updateCurrentPoll({currentPoll: question, pollIndex, choices:pollChoices});
            //store the question in the context?
            return <Redirect to="/poll/results"/>
        }

        return (

            <div className={styles.pollChoiceContainer}>
                {loaded ? <PollHeader goBack={previousPoll} showBack={pollIndex > 0}/> : null}
                <PollChoices choices={pollChoices} onSelect={selectChoice}/>
                <div className={styles.submitContainer}>
                    <PollSubmit selectedChoice={selectedChoice} onSubmitted={submitAnswer}/>
                </div>
            </div>
        )
    }

    if (loaded) {
        return showPoll();
    }
    return <p>Loading...</p>
}
