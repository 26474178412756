import React, {useContext, useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {PollContext} from "../../context/PollContext";
import {Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import PollResults from "../../components/Poll/PollResults";
import AvatarThumbnail from "../../components/Images/AvatarThumbnail";
import {AvatarizeName} from "../../services/Utilities";
import globe from "../../assets/images/globe-xs.png";
import {AppContext} from "../../context/AppContext";
import ThinksterButton from "../../components/Buttons/ThinksterButton";
import PollHeader from "../../components/Poll/PollHeader";
import {getPoll, getPollMapData} from "../../services/PollsService";
import IconObject from "../../components/Images/IconObject";
import CommentTextField from "../../components/Inputs/CommentTextField";

const useStyles = makeStyles(theme => ({
    pollResultsContainer: {
        height:"100%",
        display:'flex',
        flexDirection:'column',
    },
    commentsContainer: {
      marginTop: 'auto',
    },
    commentsInputContainer: {
        flexGrow: 9,
        paddingLeft:5,
        paddingRight: 5
    },
    iconContainer: {
        height: 48,
        display:'flex',
        alignItems: 'center',
    },
    mapIcon: {
        borderRadius:15
    },
    nextContainer: {
        paddingLeft:5,
        marginLeft: "auto",
        display:"flex",
        alignItems: "center",

    },
    clickable: {
        cursor: 'pointer',
    }

}));

const PollResultsScreen = ({history}: {history:any}) => {
    const styles = useStyles({});

    const context = useContext(AppContext);
    const {pollIndex, choices, currentPoll, polls, updateCurrentPoll} = useContext(PollContext);

    const [currentChoices, setCurrentChoices] = useState(null);

    useEffect(() => {
        async function checkChoices() {
            let _choices = choices;
            if (!_choices) {
                //need to reload the Poll...
                const {Choices} = await getPoll(context.token, currentPoll.PollId);
                _choices = Choices;
                updateCurrentPoll({choices: _choices});
            }
            setCurrentChoices(_choices);
        }

        checkChoices();
    },[choices]);

    const [seeResponses, setSeeResponses] = useState(false);

    const previousPoll = () => {
        let newPollIndex = pollIndex-1;
        updateCurrentPoll({currentPoll: polls[newPollIndex], pollIndex:newPollIndex, choices: null});
        history.goBack();
    };

    const nextPoll = () => {
        let newPollIndex = pollIndex+1;

        updateCurrentPoll({pollIndex:newPollIndex, choices, currentPoll: polls[newPollIndex]});
        history.push('/poll/view')
    };

    const goToComments = () => {
        history.push('/poll/comments');
    };

    const getMapData = async () => {
        history.push("/poll/map");

    }

    if (seeResponses) {
        return <Redirect push to="/poll/resultresponses"/>
    }

    return (
        <div className={styles.pollResultsContainer}>
            <PollHeader goBack={previousPoll} showBack={pollIndex > 0}/>
            {choices ? <PollResults choices={choices}/> : null}
            <Grid container alignItems="center" className={styles.commentsContainer}>
                <Grid  item xs={2} >
                    <Grid container>
                    <Grid item xs className={styles.iconContainer}>
                        <div onClick={() => setSeeResponses(true)} className={styles.clickable}>
                            <AvatarThumbnail url={context.profile.AvatarUrl}
                                             alt={AvatarizeName(context.profile.DisplayName)} width={36} radius={36}/>
                        </div>
                    </Grid>
                    <Grid item xs className={styles.iconContainer}>
                        <div onClick={getMapData} className={styles.clickable}>
                            <IconObject urlObject={globe} radius={36} width={36}/>
                        </div>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs className={styles.commentsInputContainer}>
                    <Grid item xs>
                        <CommentTextField click={goToComments} textInputRef={null} autoFocus={false}></CommentTextField>
                    </Grid>
                    {pollIndex+1 < polls.length ?
                        <Grid item className={styles.nextContainer}>
                            <ThinksterButton onSubmitted={nextPoll} label="Next"/>
                        </Grid> : null}
                </Grid>

            </Grid>
        </div>
    )
}

export default PollResultsScreen;