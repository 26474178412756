import PollHeader from "../../components/Poll/PollHeader";
import React, {useContext,  useRef, useState} from "react";
import {addPollComment} from "../../services/PollsService";
import {AppContext} from "../../context/AppContext";
import {PollContext} from "../../context/PollContext";
import PollComments from "../../components/PollComment/PollComments";
import {Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import ThinksterButton from "../../components/Buttons/ThinksterButton";

import open from '../../assets/images/open-xs.png'
import close from '../../assets/images/close-xs.png'
import IconObject from "../../components/Images/IconObject";
import CommentTextField from "../../components/Inputs/CommentTextField";

const useStyles = makeStyles(theme => ({
    rounded: {
        borderRadius:15
    },
    rightAlign: {
        marginLeft: "auto"
    },
    totalRecords: {
        fontWeight: 900,
        color: "#989898",
        fontSize: 15
    },
    commentsContainer: {
        display:'flex',
        flexDirection:'column',
        height:'100%'
    },
    commentsInputContainer: {
        marginTop: 'auto',
    }
}));

const PollCommentsScreen = ({history}) => {
    const commentRef = useRef(null);
    const styles = useStyles({});
    const context = useContext(AppContext);
    const {currentPoll} = useContext(PollContext);

    const [totalRecords, setTotalRecords] = useState(0);
    const [pollComment, setPollComment] = useState(null);
    const [reload, setReload] = useState(Date.now());
    const [fullScreen, setFullScreen] = useState(false);

    const childRef = useRef();

    const addComment = async () => {

        if (pollComment !== "") {
            await addPollComment(context.token, currentPoll.PollId, pollComment);
            setReload(Date.now());
            // @ts-ignore
            //childRef.current.scrollToBottom();
            await childRef.current.reloadComments();
            commentRef.current.value = "";
        }
    };

    return (
        <div className={styles.commentsContainer}>
            <PollHeader goBack={() => history.goBack()} showQuestionCount={false}/>
            <div>
                <Grid container spacing={2} justify="flex-start" alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="h6" display="block" align="left" className={styles.totalRecords}>
                            Comments {totalRecords}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <div onClick={() => setFullScreen(!fullScreen)}>
                            {fullScreen ?
                                <IconObject urlObject={close}/> :
                                <IconObject urlObject={open}/>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>

            <PollComments fullScreen={fullScreen} onReload={() => setReload(Date.now())} ref={childRef} onTotalRecords={(total) => setTotalRecords(total)}/>
            {fullScreen ? null :
                <div className={styles.commentsInputContainer}>
                    <Grid container alignItems="center">
                        <Grid item xs={10}>
                            <CommentTextField  change={(event) => setPollComment(event.target.value)} textInputRef={commentRef} ></CommentTextField>
                        </Grid>
                        <Grid item className={styles.rightAlign}>
                            <ThinksterButton onSubmitted={addComment} label="Enter"/>
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}

export default PollCommentsScreen;