import PollResponses from "../../components/PollResponse/PollResponses";
import React, {useContext} from "react";
import {PollContext} from "../../context/PollContext";
import PollHeader from "../../components/Poll/PollHeader";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        display:'flex',
        flexDirection:'column',
        height:'100%'
    },
}))

const PollResultsResponsesScreen = ({history}: {history: any}) => {
    const styles = useStyles({});
    const {currentPoll} = useContext(PollContext);
    return (
        <div className={styles.container}>
            <PollHeader goBack={() => history.goBack()} showQuestionCount={false}/>
            <PollResponses poll={currentPoll}/>
        </div>
    )
}

export default PollResultsResponsesScreen;