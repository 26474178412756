import axios from 'axios';
import {PollChoiceModel} from "../models/PollChoiceModel";
import {PollQuestionModel} from "../models/PollQuestionModel";
import {Visibility} from "../models/VisibilityEnum";
import {PollResponseModel} from "../models/PollResponseModel";
import {getBounds} from "./LocationService";

const APIURL = process.env.REACT_APP_API_URL;

export const getPollFromEvent = (vendorKey: string): Promise<{}> => {
    //return axios.post(`${APIURL}/api/client/event`, {ApplicationToken: token, VendorKey: vendorKey}).then(res => res.data);
    return axios.get(`${APIURL}/api/v1/client/event`, {headers: {"X-Authorization":vendorKey}}).then(res => res.data);
};

export const getPoll = (token: string, pollId: number): Promise<{Choices: PollChoiceModel[], Question: PollQuestionModel}>  => {
    return axios.get(`${APIURL}/api/v1/polls/${pollId}`, {headers: {"X-ApplicationToken":token}}).then(res => res.data);
};

export const getPollMapData = async (token: string, pollId: number, location: {lat, lng}, zoom = 10) => {
    const bounds = await getBounds(location);
    const data = {
        "Zoom": zoom,
        "VisualizationTypeCode":"GE",
        "Bounds": bounds
    };

    return axios.post(`${APIURL}/api/v1/polls/${pollId}/map`, data, {headers: {"X-ApplicationToken":token}}).then(res => res.data);
}

export const getPollResponse = (vendorKey: string, pollId: number): Promise<PollResponseModel[]> => {
    return axios.get(`${APIURL}/api/v1/polls/${pollId}/responses`,{headers: {"X-Authorization":vendorKey}}).then(res => res.data);
};

export const submitStandardPoll = (token: string, pollId: number, ResponseVisibility: Visibility, Response : number,
                                   Latitude?: number, Longitude? : number) => {
    return axios.post(`${APIURL}/api/v1/polls/${pollId}`, {
        ResponseVisibility, Response, Latitude, Longitude
    }, {headers: {"X-ApplicationToken": token}}).then(res => res.data);
};

export const getPollComments = (token: string, pollId: number, lastReplyCommentId?: number) => {
    let url = `${APIURL}/api/v1/polls/${pollId}/comments`

    if (lastReplyCommentId)  {
        url += `/fromLastComment/${lastReplyCommentId}`
    }
    return axios.get(url, {headers: {"X-ApplicationToken": token}}).then(res => res.data);
};

export const getPollCommentReplies = (token: string, pollId: number, commentId: number) => {
    return axios.get(`${APIURL}/api/v1/polls/${pollId}/comments/${commentId}`, {headers: {"X-ApplicationToken": token}}).then(res => res.data.Comments);
};

export const addPollComment = (token: string, pollId: number, pollContent: string, replyToComment: number = null) => {
    return axios.post(`${APIURL}/api/v1/polls/${pollId}/comments`, {CommentText:pollContent, ReplyToCommentId: replyToComment},{headers: {"X-ApplicationToken": token}}).then(res => res.data);
}