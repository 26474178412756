


export function setVendorToken(vendorKey, token) {
    const vendorTokenKey = localStorage.getItem('vendorToken');
    if (vendorTokenKey !== null) {
        //localStorage.removeItem(vendorTokenKey);
    }
    localStorage.setItem('vendorToken', `${vendorKey}`);
    localStorage.setItem(`token`, token);
}

export function getAppToken(): string {
    return localStorage.getItem("token") as string;
}
export function getVendorToken(): string {
    return localStorage.getItem(`token`) as string;
}

export function setUserProfile(profile) {
    localStorage.setItem("profile", JSON.stringify(profile));
}

export function getUserProfile(): any {
    const profile = localStorage.getItem("profile");
    return profile === null ? null : JSON.parse(profile);
}

export function resetStorage(skipVendorToken = false) {
    localStorage.removeItem("profile");
    localStorage.removeItem(`token`)
    if (!skipVendorToken) {
        localStorage.removeItem('vendorToken');
    }

}

