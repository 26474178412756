import React, {useContext, useState} from 'react';
import PollCommentModel from "../../models/PollCommentModel";
import {Box, Dialog, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import AvatarThumbnail from "../Images/AvatarThumbnail";
import {AvatarizeName} from "../../services/Utilities";
import IconObject from "../Images/IconObject";
import lightning from "../../assets/images/lightning-xs.png";
import ThinksterButton from "../Buttons/ThinksterButton";
import {addPollComment} from "../../services/PollsService";
import {PollContext} from "../../context/PollContext";
import {AppContext} from "../../context/AppContext";


const useStyles = makeStyles(() => ({
    commentName: {
        fontWeight:900.
    },
    muted : {
        fontWeight:300,
    },
    rightAlign: {
        marginLeft: "auto"
    },
    commentResponseContainer: {
        marginLeft:10,
    },
    rounded: {
        borderRadius:15
    },
    dialogContentContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
}))

const PollReplyComment = ({comment, onSave}: {comment: PollCommentModel, onSave: Function}) => {
    const {token} = useContext(AppContext);
    const {currentPoll} = useContext(PollContext);
    const styles = useStyles({});
    const [pollComment, setPollComment] = useState(null);
    const [errors, setErrors] = useState(null);

    const replyToComment = async () => {
        try {
            await addPollComment(token, currentPoll.PollId, pollComment, comment.CommentId)
            onSave(true);
        } catch(err) {
            console.error(err);
            setErrors("Unable to add a Poll Comment");
        }
    }

    return (
        <div>

            <Grid container  className={styles.dialogContentContainer}>
                <Grid item xs={1}>
                    <AvatarThumbnail url={comment.AvatarMicrothumbUrl} alt={AvatarizeName(comment.DisplayName)} width={30} radius={15}/>
                </Grid>
                <Grid item xs container alignItems="center">
                    <Box pl={1}>
                        <Typography variant="body2" display="block" align="left" className={styles.muted}>
                            {comment.CommentText}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container alignItems="center"  className={styles.dialogContentContainer}>
                <Grid item xs={9}>
                    <TextField
                        variant="filled"
                        margin="dense"
                        required
                        fullWidth
                        name="comment"
                        label="Comment..."
                        type="text"
                        id="comment"
                        InputProps={({disableUnderline: true, className: styles.rounded})}
                        onChange={(event) => setPollComment(event.target.value)}
                    />
                </Grid>
                <Grid item xs={2} className={styles.rightAlign}>
                    <ThinksterButton onSubmitted={replyToComment} label="Enter"/>
                </Grid>
            </Grid>
        </div>
    )
}


export default PollReplyComment;