import React, {useContext, useState} from 'react';
import {compose, fromRenderProps, getContext, withContext, withHandlers, withProps, withState} from "recompose";
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker,Polygon } from "react-google-maps"
import {Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import DefaultHeader from "../Headers/DefaultHeader";
import {PollConsumer, PollContext} from "../../context/PollContext";
import { withRouter } from 'react-router-dom';
import {getPollMapData} from "../../services/PollsService";
import {AppConsumer} from "../../context/AppContext";

const transformPolygonArray = (polygonArray: number[]) => {
    //Every second grouping is a lat/lng..
    const polygons = [];
    const groups = polygonArray.length/2;
    for (let x = 0; x < groups; x++) {
        const position = x*2;
        polygons.push({lat:polygonArray[position], lng:polygonArray[position+1]});
    }
    return polygons;
}

// @ts-ignore
// @ts-ignore
const PollMap = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDXGNlDdUBcS5QALNhkhtbIMbQj6fQDjUc",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withState('pollMapData', 'setPollMapData', (props:any) =>  props.mapData),
    withState('open', 'setOpen', false),
    withState('dataPoint', 'setDataPoint', null),
    withState('zoom', 'onZoomChange', 10),
    withState('newCenter', 'setNewCenter', null),
    // @ts-ignore
    fromRenderProps(AppConsumer, ({token}) => ({token})),
    // @ts-ignore
    fromRenderProps(PollConsumer, ({currentPoll}) => ({currentPoll})),
    // @ts-ignore
    withHandlers(() => {
        const refs = {
            map: undefined,
        }

        return {
            onMapMounted: () => ref => {
                refs.map = ref
            },
            onZoomChanged: (props:any) => () => {
                console.log('zoom change');
                props.onZoomChange(refs.map.getZoom());

                props.setNewCenter(null);
            },
            onCenterChanged: (props:any) => () => {
                const center = refs.map.getCenter();
                const newCenter = {lat: center.lat(), lng: center.lng()};

                props.setNewCenter(newCenter);
            }
        }
    }),
    withScriptjs,
    withGoogleMap
)((props: any) => {
        const {currentPoll, choices} = useContext(PollContext);
        const {geolocation: {coords}, pollMapData} = props;

        if (props.newCenter) {
            getPollMapData(props.token, props.currentPoll.PollId, props.newCenter, props.zoom).then(data => {
                props.setNewCenter(false);
                props.setPollMapData(data);
            });
        }
        const viewDataPoint = (dataPoint) => {
            props.setDataPoint(dataPoint);
            props.setOpen(true);
        };

        const checkColor = (mapRecord) => {
            const idx = mapRecord.Results.indexOf(Math.max(...mapRecord.Results))
            return choices[idx].ChartColour;
        }

        return (<div>
            <GoogleMap
                ref={props.onMapMounted}
                defaultZoom={10} defaultCenter={{lat: coords.latitude, lng: coords.longitude}}
                onIdle={props.onCenterChanged}
                onZoomChanged={props.onZoomChanged}
                options={{zoomControl: false}}
                >
          {
              pollMapData.MapData
                .filter(m => m.Responses > 0)
                .map((m, idx) => {
                  return <Polygon key={idx}
                      path={transformPolygonArray(m.Polygons[0])}
                      options={{
                          fillColor: m.Responses > 0 ? checkColor(m) : 'transparent',
                          fillOpacity: 0.4,
                          strokeColor: m.Responses > 0 ? "#000" : "transparent",
                          strokeOpacity: 1,
                          strokeWeight: 1
                      }}
                                  onClick={() => viewDataPoint(m)}
                  />
            })
            }
        </GoogleMap>

            {props.open ?
        <Dialog aria-labelledby="simple-dialog-title" open={props.open} onClose={() => props.setOpen(false)}>
            <DialogTitle id="simple-dialog-title">{props.dataPoint.Name}</DialogTitle>
            <DialogContent>
                Total Responses: {props.dataPoint.Responses}
            </DialogContent>
        </Dialog> : null}

        </div>);

    }
)

export default PollMap