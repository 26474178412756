import React, {useContext} from 'react';
import {PollChoiceModel} from "../../models/PollChoiceModel";
import {PollQuestionModel} from "../../models/PollQuestionModel";
import "./Poll.css"
import PollResult from "./PollResult";
import {GridList, GridListTile, makeStyles} from "@material-ui/core";
import {PollContext} from "../../context/PollContext";

const useStyles = makeStyles(theme => ({
    gridContainer: {
        //height:330,
        alignContent: "flex-start"
    }
}))

const PollResults = ({choices}) => {


    const {currentPoll} = useContext(PollContext);

    const styles = useStyles({});
    return <GridList cellHeight="auto" className={styles.gridContainer}>
        {choices.map((choice, idx) =>
            <GridListTile cols={2} rows={2} key={idx}>
                <PollResult choice={choice} question={currentPoll}/>
            </GridListTile>
        )}
    </GridList>
    //
    // return (
    //     <div>
    //         <div className="poll-results-container">
    //             {choices.map((choice, index) => <PollResult key={index} choice={choice} question={question}/>)}
    //         </div>
    //     </div>
    // )
}

export default PollResults;
