import React from "react";
import { Route, Switch } from "react-router-dom";
import PollScreen from "../containers/PollScreen/PollScreen";
import NotFoundScreen from "../containers/NotFoundScreen/NotFoundScreen";
import UnauthenticatedRoute from "../components/Routes/UnauthenticatedRoute";
import AuthenticatedRoute from "../components/Routes/AuthenticatedRoute";
import CreateAccount from "../components/CreateAccount/CreateAccount";
import LandingScreen from "../containers/LandingScreen/LandingScreen";
import PollResultsScreen from "../containers/PollResultsScreen/PollResultsScreen";
import PollResultsResponsesScreen from "../containers/PollResultsResponsesScreen/PollResultsResponsesScreen";
import PollCommentsScreen from "../containers/PollCommentsScreen/PollCommentsScreen";
import PollLandingScreen from "../containers/PollLandingScreen/PollLandingScreen";
import SettingsScreen from "../containers/SettingsScreen/SettingsScreen";
import PollMapScreen from "../containers/PollMapScreen/PollMapScreen";

export default function Routes({childProps}) {
    return <Switch>
        <UnauthenticatedRoute path="/" exact component={LandingScreen} props={childProps}/> ?
        <UnauthenticatedRoute path="/CreateAccount" exact component={CreateAccount} props={childProps}/> ?

        <AuthenticatedRoute path="/poll" exact component={PollLandingScreen} props={childProps}/>
        <AuthenticatedRoute path="/poll/view" exact component={PollScreen} props={childProps}/>
        <AuthenticatedRoute path="/poll/results" exact component={PollResultsScreen} props={childProps}/>
        <AuthenticatedRoute path="/poll/map" exact component={PollMapScreen} props={childProps}/>
        <AuthenticatedRoute path="/poll/resultresponses" exact component={PollResultsResponsesScreen} props={childProps}/>
        <AuthenticatedRoute path="/poll/comments" exact component={PollCommentsScreen} props={childProps}/>
        <AuthenticatedRoute path="/settings" exact component={SettingsScreen} props={childProps}/>

        <Route component={NotFoundScreen}/>
    </Switch>;
}
