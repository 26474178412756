const getLocation = async () : Promise<Position> => {
    return new Promise((resolve, reject) => {
        const position = {
            coords: {
                latitude: 49.8537377,
                longitude: -97.2923083
            }, timestamp: Date.now()
        } as Position;
        resolve(position);
        /*
        navigator.geolocation.getCurrentPosition((coords) => {
            resolve(coords)
        }, (err) => {
            reject(err)
        }, {
            enableHighAccuracy: true
        })
        */
    });
}

const delta = 0.3922;
const weight = 1;

export const getBounds = async (coords) => {
    const {lat, lng} = coords;
    return {
        "East": lng + delta * weight,
        "West": lng - delta * weight,
        "North": lat + delta * weight,
        "South": lat - delta * weight
    }
};

export default getLocation;