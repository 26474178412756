import React, {useContext, useState} from 'react';
import Login from "../../components/Login/Login";
import {AppContext} from "../../context/AppContext";
import {Authenticator} from "aws-amplify-react";


export default function LoginScreen({history}){
    const {userHasAuthenticated} = useContext(AppContext);

    const onLogin = ({profile, token}) => {
        userHasAuthenticated(token, profile);
        history.push("/poll");
    }

    const signUpConfig = {
        header: 'My Customized Sign Up',
        hiddenDefaults: ["phone_number"],
        signUpFields: [
            {
                label: 'First Name',
                key: 'given_name',
                required: true,
                displayOrder: 10,
                type: 'string'
            },
            {
                label: 'Last Name',
                key: 'family_name',
                required: true,
                displayOrder: 11,
                type: 'string'
            },
            {
                label: 'Postal Code',
                key: 'postalCode',
                required: true,
                displayOrder: 12,
                type: 'string'
            },
            {
                label: 'Country',
                key: 'country_name',
                required: true,
                displayOrder: 14,
                type: 'string'
            },
        ]
    }

    return (
        <div className="loginscreen">
            <Login key={1} onLogin={onLogin}/>
        </div>
    );

}

