import {Box, Grid, makeStyles, Typography} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {PollContext} from "../../context/PollContext";
import {Redirect} from 'react-router-dom';
import "./Poll.css"
import arrowImage from "../../assets/images/arrow-xs.png";
import IconObject from "../Images/IconObject";

import ellipsis from "../../assets/images/ellipsis-xs.png";

const useStyles = makeStyles(theme => ({
    backContainer: {
        marginTop:4,
        width:30,
        height:30,
    },
    pollHeaderContainer : {
        marginTop: 16,
        marginBottom: 16
    },
    settingsContainer: {
        marginTop:-15
    }

}))

const PollHeader = ({goBack, showBack = true, showQuestionCount = true}) => {
    const styles = useStyles({});

    const {currentPoll, pollIndex, polls} = useContext(PollContext);

    const [goToSettings, setGoToSettings] = useState(false);

    if (goToSettings) {
        return <Redirect to="/settings"/>
    }

    return (
        <Grid container className={styles.pollHeaderContainer}>

            {showBack ?
                <Grid item xs={1}>
                    <div className={styles.backContainer}>
                        <div onClick={goBack}>
                             <IconObject urlObject={arrowImage}/>
                        </div>
                    </div>
                </Grid>
            : null}

            <Grid container item xs>
                <Grid item xs>
                    <div className="poll-question">{currentPoll.Question}</div>
                </Grid>
                <Grid item xs={1} className={styles.settingsContainer}>
                    <div onClick={() => {setGoToSettings(true)}}>
                        <IconObject urlObject={ellipsis}/>
                    </div>
                </Grid>
            </Grid>
            {showQuestionCount ?
                <Grid container >
                    {showBack ? <Grid item xs={1}>&nbsp;</Grid> : null}
                    <Grid item xs>
                        <Box mt={2}>
                            <Typography variant="subtitle1"
                                    className={"poll-count"}>{pollIndex + 1} of {polls.length}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                : null
            }
        </Grid>
    )
}

export default PollHeader;