import {
    Avatar,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel, Grid, Link, makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import { Route, Redirect } from "react-router-dom";


import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import React, {useCallback, useContext, useState} from "react";
import {login} from "../../services/AuthService";
import {AppContext} from "../../context/AppContext";


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    light: {
        fontWeight:300,
    },
    headerContainer: {
        fontSize:40,
    },
    heavy: {
        fontWeight: 900,
    },
    paper: {
        marginTop:20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        borderRadius: '9px',
        margin: theme.spacing(3, 0, 2),
    },
    thick: {
        borderWidth: '2px',
    },
    withOutlinedButton: {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
}));


export default function Login({onLogin}) {

    const context = useContext(AppContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLoggingIn, setErrorLoggingIn] = useState(false);
    const [gotoCreateAccountPage, setGotoCreateAccountPage] = useState(false);

    const loginHandler = useCallback(async (e) => {
        e.preventDefault();
        try {
            const result = await login(context.token, email, password, context.nonce);
            if (!result.profile.Success) {
                throw new Error("Failure to login");
            }
            onLogin && onLogin(result);
        }catch(e) {
            setErrorLoggingIn(true);
        }
    }, [email, password, onLogin]);

    const goToCreateAccount = useCallback(async(e) => {
        e.preventDefault();
        setGotoCreateAccountPage(true);
    }, [])

    const classes = useStyles({});

    if (gotoCreateAccountPage) {
        return <Redirect to="/CreateAccount"/>
    }

    return (

        <Container component="main" maxWidth="xs">
            <CssBaseline/>

            <div className={classes.paper}>
                <Typography variant="h5" component="h2" align="left" className={classes.headerContainer}>
                    <span className={classes.heavy}>Think</span>ster
                </Typography>
                <Typography variant="h6" component="h3" align="left" className={classes.light}>
                    Welcome Back!
                </Typography>
                <form className={classes.form} noValidate onSubmit={loginHandler}>
                    <TextField
                        variant="filled"
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <TextField
                        variant="filled"
                        margin="dense"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                    />

                    {errorLoggingIn ? (
                        <Typography color="error" >
                            Email/Password was not found, please try again.
                        </Typography>
                    ) : null}
                    <Grid container alignContent={"space-between"} spacing={1}>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                color="primary"
                                className={[classes.submit, classes.withOutlinedButton].join(' ')}>
                                Login
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                type="button"
                                size="large"
                                variant="outlined"
                                color="primary"
                                className={[classes.submit, classes.thick].join(" ") }
                                onClick={async (e) => await goToCreateAccount(e)}>
                                Create Account
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
