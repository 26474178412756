import axios from "axios";

const APIURL = process.env.REACT_APP_API_URL;

export const updateProfile = async (token, postalCode) => {
    const url = `${APIURL}/api/v1/profile`;
    try {
        const {data} = await axios.post(url, {
            "PostalCode":postalCode,
        },{
            headers: {"X-ApplicationToken": token}
        });
        if (!data.Success) {
            return false;
        }
        return true;
    } catch(e) {
        return false;
    }
}