import axios from 'axios';

const APIURL = process.env.REACT_APP_API_URL;

export const followUser = (token, profileId) => {
    return axios.post(`${APIURL}/api/v1/follower/${profileId}`, {}, {headers: {"X-ApplicationToken":token}});
}

export const unfollowUser = (token, profileId) => {
    return axios.delete(`${APIURL}/api/v1/follower/${profileId}`, {headers: {"X-ApplicationToken":token}});
}