import {Grid, makeStyles, TextField} from "@material-ui/core";
import React, {useRef, useState} from "react";

const useStyles = makeStyles(theme => ({
    inputCommentContainer: {
        borderRadius:15,
    },
    inputComment: {
        paddingTop: 15,
    },
    inputCommentLabel: {
        top:-5,
    },
}))

const CommentTextField = ({click = () => {},change = (event) => {}, textInputRef, autoFocus = true}) => {
    const styles = useStyles({});

    return <div>
            <TextField
                autoFocus={autoFocus}
                inputRef={textInputRef}
                variant="filled"
                margin="dense"
                required
                fullWidth
                name="comment"
                label="Comment"
                type="text"
                id="comment"
                InputLabelProps={({className: styles.inputCommentLabel})}
                InputProps={({disableUnderline: true, className: styles.inputCommentContainer})}
                inputProps={({className: styles.inputComment})}
                onClick={() => {
                    click();
                }}
                onChange={(event) => {
                    change(event);
                }}
            />
        </div>
}

export default CommentTextField;