import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {Button, Grid, Link, makeStyles, TextField, Typography} from "@material-ui/core";
import {AppContext} from "../../context/AppContext";
import {applicationTokenRequest, getProfile, logout} from "../../services/AuthService";
import {resetStorage} from "../../services/TokenManager";
import {updateProfile} from "../../services/UserService";

import validator from 'validator';

const useStyles = makeStyles(theme => ({
    settingsContainer: {
        marginTop: 24,
    }
}))

const Settings = ({onLogout}) => {
    const styles = useStyles({});
    const {profile, token, updateContext} = useContext(AppContext);

    const [userProfile, setUserProfile] = useState(null);

    const [postalCode, setPostalCode] = useState('');

    const [goToLogout, setGoToLogout] = useState(false);

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        async function getUserProfile() {
            const fullProfile = await getProfile(token);
            setUserProfile(fullProfile);
            setPostalCode(fullProfile.PostalCode);
        }

        getUserProfile();
    }, []);

    if (!userProfile) {
        return <p>Loading ...</p>
    }

    const performUpdateProfile = async () => {
        if (validator.isPostalCode(postalCode, userProfile.CountryCode.toUpperCase())) {
            await updateProfile(token, postalCode);
            setMessage("Profile was updated!");
            setTimeout(() => setMessage(""), 1500);
            setError("");
        }
        else {
            setError("Invalid Postal Code");
        }
    };

    const performLogout = async (e) => {
        // logout process...
        if (await logout(token)) {
            resetStorage(true)
            const {ApplicationToken} = await applicationTokenRequest();

            updateContext({isAuthenticated: false, token: ApplicationToken, profile: null});
            onLogout();
        } else {
            setMessage("There was an issue logging out");
        }
    }

    return (
        <div className={styles.settingsContainer}>
            {message !== "" ? (
                <Typography color="textPrimary">{message}</Typography>
            ) : null}
            {error !== "" ? (
                    <Typography color="error">{error}</Typography>
                ) : null}
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    margin="dense"
                    required
                    fullWidth
                    label="Postal Code"
                    autoFocus
                    value={postalCode}
                    onChange={(event) => {
                        setPostalCode(event.target.value)

                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Link align={"left"} onClick={() => alert("To Be Implemented")}>Reset Password</Link>
            </Grid>
        </Grid>
            <Grid container alignContent={"space-between"} spacing={1}>
                <Grid item xs>
                    <Button
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={async (e) => await performUpdateProfile()}>
                        Save
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        type="button"
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={async (e) => await performLogout(e)}>
                        Logout
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
};

export default Settings;