import React, {useContext, useEffect, useState} from "react";
import {Redirect} from 'react-router-dom';
import {getPoll, getPollFromEvent} from "../../services/PollsService";
import {AppContext} from "../../context/AppContext";
import {Typography} from "@material-ui/core";
import {PollContext} from "../../context/PollContext";

/**
 * Landing Screen to load the Event Polls, then delegate off to the Polls...
 */

const PollLandingScreen = ({history}) => {

    const {nonce}  = useContext(AppContext);
    const {updateCurrentPoll} = useContext(PollContext);

    const [allPolls, setAllPolls] = useState(null);

    useEffect(() => {

        const getQuestion = async () => {
            const polls = await getPollFromEvent(nonce);
            updateCurrentPoll({polls, pollIndex: 0});
            setAllPolls(polls);
            history.push('/poll/view');

        }
        getQuestion()
    }, []);

    return (
        <div>
            <p>Loading...</p>
            {/*{allPolls ?*/}
            {/*        (<Redirect to="/poll/view"/>) :*/}
            {/*        (<p>Loading...</p>)*/}
            {/*}*/}
        </div>

    )
}

export default PollLandingScreen;