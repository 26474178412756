import React, {useCallback, useContext, useEffect, useState} from 'react';
import './App.css';
import Routes from "./routes/Routes";
import {withRouter} from "react-router";
import {
  setUserProfile,
  resetStorage,
  setVendorToken,
} from "./services/TokenManager";
import {AppContext} from "./context/AppContext";
import {applicationTokenRequest, validateApplicationToken} from "./services/AuthService";

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';

const region = process.env.REACT_APP_REGION;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_APP_CLIENT_ID;

const config = {
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId,
    identityPoolId,
    userPoolWebClientId
  }
};
Amplify.configure(config);


const AppComponent = (props) => {

  props.history.listen((location, action) => {
    //console.log(action, location.pathname, location.state);
  })

  const appContext = useContext(AppContext);

  let {vendorKey, userProfile, token} = props;

  useEffect(() => {
    // need to set the Context vendor key and token...
    async function handleContext() {
      const defContext = {
        token,
        nonce: vendorKey,
        profile: userProfile,
        userHasAuthenticated,
        isAuthenticated: false,
      };

      if (token && vendorKey && userProfile) {

        if (await validateApplicationToken(vendorKey, token, userProfile.ProfileId)) {
          // skip login...
          defContext.isAuthenticated = true;
        }
      } else {

        const {ApplicationToken} = await applicationTokenRequest();
        defContext.token = ApplicationToken;
      }
      appContext.updateContext(defContext);
      setIsAuthenticated(defContext.isAuthenticated);

      setLoading(false);
    }

    handleContext();
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const invalidateUser = useCallback(() => {
    setIsAuthenticated(false);
    resetStorage();
  }, [vendorKey]);

  const userHasAuthenticated = useCallback((newToken, profile) => {
    setVendorToken(vendorKey, newToken);
    setUserProfile(profile);
    setIsAuthenticated(true);

    appContext.updateContext({vendorKey, profile, token: newToken});
  }, [vendorKey]);


    return (
        <div className="App container">
            {loading ? <p>Loading...</p>:
                <Routes childProps={{isAuthenticated, invalidateUser}}/>
            }
        </div>
   )
};

const App = withRouter(AppComponent);
export default App;
