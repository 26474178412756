import React, {useContext, useEffect, useState} from 'react';
import Login from "../../components/Login/Login";
import {AppContext} from "../../context/AppContext";
import {validateApplicationToken} from "../../services/AuthService";
import LoginScreen from "../LoginScreen/LoginScreen";
import {Redirect} from 'react-router-dom';


export default ({history}) => {
    // we are coming from "/" - we need to determine if there is a pre-existing profile, etc.
    const {userHasAuthenticated, profile, nonce, token} = useContext(AppContext);
    const [showLogin, setShowLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (loading) {
            if (profile && token) {
                validateApplicationToken(nonce, token, profile.ProfileId)
                    .then(r => {
                        setLoading(false);
                        setShowLogin(!r);
                        userHasAuthenticated(token, profile);
                    })
            } else {
                setShowLogin(true);
                setLoading(false);
            }
        }

    }, [])


    if (!loading) {
        if (showLogin) {
            return <LoginScreen history={history}/>
        } else {
            return <Redirect to="/poll/view"/>
        }
    }
    return (
        <p>Loading...</p>
    );

}

