import React, {createContext, useState} from "react";

export const PollContext = createContext({
    choices: null,
    pollIndex: 0,
    polls: null,
    currentPoll: null,
    updateCurrentPoll: state => {}

});

const PollContextProvider = ({children}) => {

    const updateCurrentPoll = ({currentPoll, polls, pollIndex, choices}) => {
        updatePoll(prevState => {
            const newState = {...prevState}

            newState.currentPoll = typeof currentPoll === 'undefined' ? prevState.currentPoll : currentPoll;
            newState.polls = typeof polls === 'undefined' ? prevState.polls : polls;
            //Preserve the previousChoices whenenver we update
            newState.choices = typeof choices === 'undefined' ? prevState.choices : choices;

            newState.pollIndex = typeof pollIndex === 'undefined' ? prevState.pollIndex : pollIndex;
            return newState;
        });
    }

    const defState = {
        currentPoll: null,
        choices: null,
        pollIndex: 0,
        polls: null,
        updateCurrentPoll
    }
    const [context, updatePoll] = useState(defState);
    return (
        <PollContext.Provider value={context}>
            {children}
        </PollContext.Provider>
    )
}

export const PollProvider = PollContextProvider;
export const PollConsumer = PollContext.Consumer;