import React, {useCallback, useContext, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {Button, Container, CssBaseline, Grid, makeStyles, MenuItem, TextField, Typography} from "@material-ui/core";
import {createNewAccount, login} from "../../services/AuthService";
import {Redirect} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import validator from 'validator';
import ErrorComponent from "../Headers/Error";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    light: {
        fontWeight:300,
    },
    headerContainer: {
        fontSize:40,
    },
    heavy: {
        fontWeight: 900,
    },
    paper: {
        marginTop:20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        borderRadius: '9px',
        margin: theme.spacing(3, 0, 2),
    },
    thick: {
        borderWidth: '2px',
    },
    withOutlinedButton: {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
}));


export default function CreateAccount({history, onSignup}) {

    const classes = useStyles({});

    const context = useContext(AppContext);

    const [values, setValues] = useState({
        FirstName: "",
        LastName: "",
        Password: "",
        EmailAddress: "",
        ConfirmPassword: "",
        CountryCode: "ca",
        PostalCode: "",
    }, );

    const [errors, setErrors] = useState({});
    const [masterError, setMasterError] = useState({});

    const handleChange = event => {
        event.persist();
        setValues(oldValues => ({
                ...oldValues,
                [event.target.name]: event.target.value,
            })
        )
    }
    const [gotoLoginPage, setGotoLoginPage] = useState(false);

    const createAccountHandler = useCallback(async (e) => {
        e.preventDefault();
        if (validateForm()) {

            const response = await createNewAccount(context.token, values);
            if (!response.Success) {
                const msg = (response.Message ? response.Message : "There was an error creating this account.");
                setMasterError({"error":msg});
                return;
            }
            await gotoLogin();
        }

    }, [values, onSignup]);

    const gotoLogin = async () => {
        try {
            const result = await login(context.token, values.EmailAddress, values.Password, context.nonce);
            if (!result.profile.Success) {
                throw new Error("Failure to login");
            }
            const {profile, token} = result;
            context.userHasAuthenticated(token, profile);
            history.push("/poll");
        }catch(err) {
            const msg = "There was an error auto-logging you in.  We will redirect to the login page shortly.";
            setMasterError({"error":msg});
            setTimeout(() => setGotoLoginPage(true), 2000);

            //setGotoLoginPage(true);
        }
    };

    const validateForm = () => {
        let hasErrors = false;
        setErrors({});
        // @ts-ignore
        if (!validator.isPostalCode(values.PostalCode, values.CountryCode.toUpperCase())) {
            addError('postalCode', 'Invalid Postal Code');
            hasErrors = true;
        }
        if (validator.isEmpty(values.FirstName)) {
            addError('firstName', 'First Name is required');
            hasErrors = true;
        }
        if (validator.isEmpty(values.LastName)) {
            addError('lastName','Last Name is required');
            hasErrors = true;
        }
        if (validator.isEmpty(values.EmailAddress) ) {
            addError('email', 'Email is required');
            hasErrors = true;
        }
        if (!validator.isEmpty(values.EmailAddress) && !validator.isEmail(values.EmailAddress)) {
            addError('email', 'Email is not valid');
            hasErrors = true;
        }
        if (validator.isEmpty(values.Password)) {
            addError('password', 'Password is required');
            hasErrors = true;
        }
        if (validator.isEmpty(values.ConfirmPassword)) {
            addError('confirmPassword', 'Confirm Password is required');
            hasErrors = true;
        }

        if (!validator.isEmpty(values.Password) && !validator.isEmpty(values.ConfirmPassword) && !validator.equals(values.Password, values.ConfirmPassword)) {
            addError('passwordMessage', 'Passwords must match');
            hasErrors = true;
        }
        if (values.Password.length < 6 || !validator.matches(values.Password, /[0-9]/)
            || !validator.matches(values.Password, /[A-Z]/) || !validator.matches(values.Password, /[a-z]/) ) {
            addError('passwordMessage', 'Must be at least 6 characters, contain an Upper case letter, a lower case letter and a digit.')
            hasErrors = true;
        }
        return !hasErrors;
    }

    const addError = (code, message) => {
        setErrors((oldValues) => ({...oldValues, [code]: message}));

        setMasterError({"error":"Required fields must be filled out"})
    }

    if (gotoLoginPage) {
        return <Redirect to="/"/>
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>

            <div className={classes.paper}>
                <Typography variant="h5" component="h2" align="left" className={classes.headerContainer}>
                    <span className={classes.heavy}>Think</span>ster
                </Typography>
                <form className={classes.form} noValidate onSubmit={createAccountHandler}>

                    <ErrorComponent errors={masterError} name="error"/>
                    <Grid container alignContent={"space-between"} spacing={1}>
                        <Grid item xs>
                            <TextField
                                variant="filled"
                                margin="dense"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="FirstName"
                                autoComplete="firstName"
                                autoFocus
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="filled"
                                margin="dense"
                                required
                                fullWidth
                                name="LastName"
                                label="Last Name"
                                id="lastName"
                                autoComplete="lastName"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="filled"
                                margin="dense"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="EmailAddress"
                                autoComplete="email"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth margin="dense">
                                <InputLabel htmlFor="filled-countryCode-simple">Country</InputLabel>
                                <Select variant="filled"
                                        value={values.CountryCode}
                                        name="CountryCode"
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'countryCode',
                                            id: 'filled-countryCode-simple',
                                        }}
                                >
                                    <MenuItem value={"ca"}>Canada</MenuItem>
                                    <MenuItem value={"us"}>United States</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="filled"
                                margin="dense"
                                required
                                fullWidth
                                name="PostalCode"
                                label="Postal Code"
                                type="input"
                                id="postalCode"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="filled"
                                margin="dense"
                                required
                                fullWidth
                                name="Password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="filled"
                                margin="dense"
                                required
                                fullWidth
                                name="ConfirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ErrorComponent errors={errors} name="passwordMessage"/>
                        </Grid>
                    </Grid>
                    <Grid container alignContent={"space-between"} spacing={1}>
                        <Grid item xs>
                            <Button
                                type="button"
                                fullWidth
                                size="large"
                                variant="outlined"
                                color="primary"
                                className={[classes.submit, classes.thick].join(' ')}
                                onClick={gotoLogin}
                            >
                                Login
                            </Button>
                        </Grid>
                        <Grid item xs={8}>
                            <Button
                                fullWidth
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                className={[classes.submit, classes.withOutlinedButton].join(" ") }
                            >
                                Create Account
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}