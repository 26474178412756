import React from 'react';
import {Typography} from "@material-ui/core";

const ErrorComponent = ({name, errors}) => {
    const message = errors[name];
    return <div>
        {message !== "" ? (
            <Typography color="error">{message}</Typography>
        ) : null}
    </div>
}

export default ErrorComponent;