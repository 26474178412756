import React from "react";
import { Route, Redirect } from "react-router-dom";
import qs from "qs";

export default ({ component: C, props: cProps, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            cProps.isAuthenticated
                ? <C {...props} {...cProps} />
                : <Redirect
                    to={`/?redirect=${props.location.pathname}&${qs.stringify(qs.parse(props.location
                        .search, {ignoreQueryPrefix: true}))}`}
                />}
    />;
