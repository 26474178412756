import {Grid, makeStyles, Typography} from "@material-ui/core";
import React, {useContext, useState} from "react";
import arrowImage from "../../assets/images/arrow-xs.png";
import IconObject from "../Images/IconObject";

const useStyles = makeStyles(theme => ({
    backContainer: {
        width:30,
        height:30,
        marginTop:4,
    },
    pollHeaderContainer : {
        marginBottom: 16,
        marginTop: 16,
    }

}))
const DefaultHeader = ({label, showBack, goBack}) => {


        const styles = useStyles({});


        return (
            <Grid container className={styles.pollHeaderContainer}>
                <Grid item xs={1}>
                    {showBack ?
                        <div className={styles.backContainer}>
                            <div onClick={goBack}>
                                <IconObject urlObject={arrowImage}/>
                            </div>
                        </div>
                        : null}
                </Grid>
                <Grid container item xs>
                    <Grid item xs>
                        <div className="poll-question">{label}</div>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
export default DefaultHeader;