import React, {createContext, useState} from "react";
import {PollContext} from "./PollContext";

export const AppContext = createContext({
    isAuthenticated: null,
    userHasAuthenticated: null,
    token: null,
    profile: null,
    nonce: null,
    updateContext: state => {}
});

const AppContextProvider = ({children}) => {

    const update = ({isAuthenticated, userHasAuthenticated, token, profile, nonce}) => {
        updateState(prevState => {
            const newState = {...prevState};
            newState.token = typeof token === 'undefined'? newState.token : token;
            newState.isAuthenticated = typeof isAuthenticated === 'undefined' ? newState.isAuthenticated : isAuthenticated;
            newState.userHasAuthenticated = typeof userHasAuthenticated === 'undefined' ? newState.userHasAuthenticated : userHasAuthenticated;
            newState.profile = typeof profile === 'undefined' ? newState.profile : profile;
            newState.nonce = typeof nonce === 'undefined' ? newState.nonce : nonce;
            return newState;
        });
    }

    const defState  = {
        isAuthenticated: false,
        userHasAuthenticated: false,
        token: null,
        profile: null,
        nonce: null,
        updateContext: update
    }

    const [context, updateState] = useState(defState);

    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    )
}


export const AppProvider = AppContextProvider;
export const AppConsumer = AppContext.Consumer;
