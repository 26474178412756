import axios from 'axios';
import NewAccount from "../models/NewAccount";
import { Auth } from 'aws-amplify';


const APIURL = process.env.REACT_APP_API_URL;

const loginData = {
    ApplicationId: '953ffee976d03b818e63b594b97bae46',
    ApplicationSecret: '1abf867278f613425f1862117190e258',
    FCMToken: ""
};


export const login = async (ApplicationToken, EmailAddress, Password, VendorAccessKey): Promise<any> => {


    try {
        const data = await Auth.signIn(EmailAddress, Password);
        console.log(data);
        return {};
    } catch (e) {
        console.error(e);
    }
    // const loginUrl = `${APIURL}/api/v1/login`;
    //
    //
    // const body = {ApplicationToken, VendorAccessKey, EmailAddress, Password};
    // const {data} = await axios.post(loginUrl, body, {
    //     headers: {'Content-Type': 'application/json',},
    // });
    // if (data.Code && data.Code === 'ValidationFailure') {
    //     throw new Error('Invalid Login');
    // }
    // let returned = {profile:data, token: ApplicationToken};
    // return returned;
}

export const logout = async (token) => {
    const logoutUrl = `${APIURL}/api/v1/logout`;

    const {data} = await axios.post(logoutUrl, {}, {
        headers: {'X-ApplicationToken': token}
    });

    return data.Success;

}

export const applicationTokenRequest = async (): Promise<{ApplicationToken: string}> => {
    const url = `${APIURL}/api/v1/token`;

    try {
        const {data} = await axios.post(url, loginData, {
            headers: {'Content-Type': 'application/json',},
        });
        return data;
    } catch (e) {
        console.log(e);
        throw new Error(e);
    }
}

export const verifyApplicationToken = async (token) : Promise<any> => {
    const url = `${APIURL}/api/v1/token`;
    try {
        const {data} = await axios.get(url, {
            headers: {"X-ApplicationToken": token}
        });
        if (!data.Success) {
            return false;
        }
        return true;
    } catch(e) {
        return false;
    }
}

export const validateApplicationToken = async (vendorKey, token, profile) : Promise<any> => {
    const url = `${APIURL}/api/v1/token/associate`;
    try {
        const {data} = await axios.post(url, {ApplicationToken: token}, {
            headers: {'Content-Type': 'application/json', 'X-Authorization' : vendorKey},
        });
        if (data.Message) {
            return false;
        }
        if (data.ProfileId === profile) {
            return true;
        }
        return false;
    } catch(e) {
        throw new Error(e);
    }
}

export const getProfile = async(token) : Promise<any> => {
    const url = `${APIURL}/api/v1/profile`
    try {
        const {data} = await axios.get(url, {
            headers: {"X-ApplicationToken": token}
        });
        return data;
    } catch (e) {
        throw new Error(e);
    }
}

export const createNewAccount = async(token, accountModel: NewAccount): Promise<any> => {
    try {
        const data = await Auth.signUp({
            username: accountModel.EmailAddress,
            password: accountModel.Password,
            attributes: {
                given_name: accountModel.FirstName,
                family_name: accountModel.LastName,
                email: accountModel.EmailAddress,
                "custom:custom:postalCode": accountModel.PostalCode,
                "custom:custom:country": accountModel.CountryCode
            }
        })



        return {Success: true};
    } catch(err) {

        console.error(err);
        return {Success: false, Message:err.message};
    }

    // const url = `${APIURL}/api/v1/newAccount`;
    // try {
    //     const {data} = await axios.post(url, accountModel,
    //         {
    //             headers: {"X-ApplicationToken": token},
    //         }
    //     );
    //     return data;
    // } catch(e) {
    //     throw new Error(e);
    // }
}
