import React, {useState} from 'react';
import PollChoice from "./PollChoice";
import {PollChoiceModel} from "../../models/PollChoiceModel";
import "./Poll.css"
import {GridList, GridListTile, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    gridContainer: {
        height:330,
        alignContent: 'flex-start'
    }
}))

const PollChoices = ({choices, onSelect}: {choices: PollChoiceModel[], onSelect: Function}) => {

    const styles = useStyles({});
    const [selectedChoice, setSelectedChoice] = useState(null);

    const select = (obj) => {
        setSelectedChoice(obj);
        onSelect(obj);
    }

    return (
        <GridList cellHeight="auto" className={styles.gridContainer}>
            {choices.map((choice, idx) =>
            <GridListTile cols={2} rows={2} key={idx}>
                <PollChoice choice={choice} onSelect={select} selectedChoice={selectedChoice}/>
            </GridListTile>
            )}
        </GridList>
    )

    /*return <div className="poll-choices-container">
        {choices.map((choice, idx) => <PollChoice choice={choice} onSelect={select} selectedChoice={selectedChoice} key={idx}/>)}
    </div>*/
}

export default PollChoices;
