import React from 'react';
import {PollChoiceModel} from "../../models/PollChoiceModel";
import {PollQuestionModel} from "../../models/PollQuestionModel";

import "./Poll.css"


const PollResult = ({choice, question}: {choice: PollChoiceModel, question: PollQuestionModel}) => {
    const decimalPercentage = choice.SelectionCount/question.ResponseCount;
    const percentage = Math.round(decimalPercentage*100);
    const width = percentage;
    const resultAnsweredStyle = {
        width: `${width}%`,
        backgroundColor:choice.ChartColour
    }

    return (
        <div className="poll-result-container">
            <div className="poll-result">
                {choice.Choice}
            </div>
            <div className="poll-result-answered" style={resultAnsweredStyle}>
            </div>
            <div className="poll-result-percentage">
                {percentage}%
            </div>

        </div>
    )
}

export default PollResult;
