import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {GridListTile, makeStyles, Typography} from "@material-ui/core";
import PollComment from "./PollComment";
import {getPollComments} from "../../services/PollsService";
import {AppContext} from "../../context/AppContext";
import {PollContext} from "../../context/PollContext";
import InfiniteScroll from 'react-infinite-scroller';


const useStyles = makeStyles(({
    gridContainer: {
        marginLeft: (props: any) => props.inset,
        height: '100%',
        overflowY:'scroll',
    },
    gridList: {
        height: (props: any) => props.ignoreReplyTo ? "inherit" : 'inherit',

        flex:0.6,
        transform: 'translateZ(0)',
    }
}))


const PollComments = forwardRef((props:any, ref) => {

    const context = useContext(AppContext);
    const {currentPoll} = useContext(PollContext);

    const [comments, setComments] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [hasMoreRecords, setHasMoreRecords] = useState(true);

    const {onTotalRecords, fullScreen, inset = 0, ignoreReplyTo = false, small = false, onReload = () => {}} = props;

    const styles = useStyles({inset, ignoreReplyTo});

    useImperativeHandle(ref, () => ({
        async reloadComments() {
            await loadComments();
        }
    }));

    let gridClass = styles.gridList;
    if (fullScreen) {
        gridClass = null
    }

    const runOnReload = () => {
        onReload();
    };


    const loadComments = async () => {
        const comments = await getPollComments(context.token, currentPoll.PollId);

        setTotalRecords(comments.TotalRecords);
        onTotalRecords(comments.TotalRecords);

        setComments(comments.Comments);
        setHasMoreRecords(comments.Comments.length < comments.TotalRecords);
    }

    useEffect(() => {
        loadComments();
    }, []);

    const createItem = (idx, comment) => {
        return <div key={idx} >
            <PollComment comment={comment} ignoreReplyTo={ignoreReplyTo} small={small} onReload={()=> runOnReload()}/>
        </div>
    }

    const noCommentsTile = () => {
        return (
            <div key={0}>
                <Typography variant="h6" align="left">No Comments Yet!</Typography>
            </div>
        );
    }

    const loadMore = async () => {
        console.log('loading more');

        const lastCommentId = comments[comments.length-1].CommentId;
        const moreComments = await getPollComments(context.token, currentPoll.PollId, lastCommentId);
        if (moreComments.Comments.length === 0) {
            setHasMoreRecords(false);
            return;
        }
        const totalComments = [...comments, ...moreComments.Comments];

        setHasMoreRecords(totalComments.length < totalRecords);
        setComments(totalComments);
    }

    const commentElements =  comments.length > 0
        ? comments.map((comment, idx) => (createItem(idx, comment)))
        : [];

    return (
        <div className={styles.gridContainer}>
            {comments.length > 0 ?
                    <InfiniteScroll
                        useWindow={false}
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={hasMoreRecords}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                        {commentElements}
                    </InfiniteScroll>
                : noCommentsTile()
            }
            {/*<GridList cellHeight="auto" spacing={4} className={gridClass}>*/}
            {/*    {comments.length > 0 ?*/}
            {/*        comments.map((comment, idx) => (createItem(idx, comment))) :*/}
            {/*        noCommentsTile()*/}
            {/*    }*/}
            {/*</GridList>*/}

        </div>
    )

})

export default PollComments;