import React from "react";
import {makeStyles} from "@material-ui/core";
import classNames from 'classnames';


const useStyles = makeStyles(themes => ({
    avatarContainer: {
        backgroundColor:"#0074CD",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    avatarBase: {
        borderRadius: (props:any) => props.radius,
        width: (props:any) => props.width,
        height: (props:any) => props.width,
    },
    avatar: {
        color: "#ffffff"
    }
}))

const AvatarThumbnail = ({url, alt, width = 30, radius = 15}: {url:string, alt: string, width: number, radius: number}) => {
    const styles = useStyles({radius, width});
    const classes = classNames(styles.avatarContainer, styles.avatarBase);

    if (!url) {
        return <div className={classes}>
            <span className={styles.avatar}>{alt}</span>
        </div>
    }
    return (<div className={classes}>
        <img src={url} className={styles.avatarBase}/>
    </div>)
}

export default AvatarThumbnail;