import React, {useContext, useState} from 'react';
import "../Poll/Poll.css";
import {makeStyles} from "@material-ui/core";
import classNames from 'classnames';


const useStyles = makeStyles(theme => ({
    pushed : {
        backgroundColor: "#1E48BB",
    }
}))

const ThinksterButton = ({onSubmitted, label}: {onSubmitted: Function, label: string}) => {
    const styles = useStyles({});
    const [isDown, setIsDown] = useState(false);
    const classes = classNames("poll-next-container", isDown ? styles.pushed : "");

    return (
        <div className={classes} onMouseDown={() => setIsDown(true)} onClick={() => onSubmitted()} onMouseUp={() => setIsDown(false)}>
            <div className="poll-submit">
                {label}
            </div>
        </div>
    )
}

export default ThinksterButton;
