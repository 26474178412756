import React from "react";
import {getPollResponse} from "../../services/PollsService";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../context/AppContext";
import PollResponse from "./PollResponse";
import {GridList, GridListTile, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    gridContainer: {
        marginLeft: (props: any) => props.inset,
        height: '100%',
        overflowY:'scroll',
    },
    responsesContainer: {
        marginTop:20
    },
    responseContainer: {
        minHeight: 46
    }
}))

const PollResponses = ({poll}) => {
    const styles = useStyles({});
    const context = useContext(AppContext);
    // we need to go out and get poll responses...

    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        async function pollResponses() {
            const resps = await getPollResponse(context.nonce, poll.PollId);
            resps.sort((a,b) => {
                if (a.FollowingThem && !b.FollowingThem) {
                    return -1;
                }else if (a.FollowingThem && b.FollowingThem) {
                    return a.DisplayName.localeCompare(b.DisplayName);
                }
                return a.DisplayName.localeCompare(b.DisplayName);
            })
            setResponses(resps);
            setLoading(false);
        }
        pollResponses();
    }, [])


    return (
        <div className={styles.gridContainer}>

        <GridList cellHeight="auto" style={{"marginRight":2}}>
            {responses.map((r, idx) => {
                return (
                    <GridListTile key={idx} cols={2} rows={1} className={styles.responseContainer}>
                        <PollResponse response={r}/>
                    </GridListTile>
                )
            })}
            {loading ?
                (<GridListTile cols={2} rows={1}><p>Loading...</p></GridListTile>) :
                null
            }
        </GridList>
        </div>
    )
};

export default PollResponses;