import React, {useContext, useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, Grid} from "@material-ui/core";
import {PollContext} from "../../context/PollContext";
import {getPollMapData} from "../../services/PollsService";
import {AppContext} from "../../context/AppContext";
import PollMap from "../../components/PollMap/PollMap";
import getLocation from "../../services/LocationService";
import DefaultHeader from "../../components/Headers/DefaultHeader";

const useStyles = makeStyles(theme => ({
    progressContainer: {
        textAlign:"center"
    },

    progress: {
        margin: theme.spacing(2),
    },
}));

const PollMapScreen = ({history}) => {
    const styles = useStyles({});

    const {token} = useContext(AppContext);
    const {currentPoll} = useContext(PollContext);

    const [mapData, setMapData] = useState(null);
    const [location, setLocation] = useState(null);

    useEffect(() => {

        async function loadMapData() {
            const loc = await getLocation();
            const latLng = {lat: loc.coords.latitude, lng: loc.coords.longitude};
            const mapData = await getPollMapData(token, currentPoll.PollId, latLng);
            setLocation(loc);

            setMapData(mapData);
        }

        loadMapData();
    }, []);


    if (mapData) {
        const props = {geolocation: location, mapData};
        console.log(props);
        return  <div>
            <DefaultHeader showBack={true} label={currentPoll.Question} goBack={() => history.goBack()}/>
            <PollMap {...props}/>
        </div>
    }

    return (<Grid container justify="center" alignContent="center" className={styles.progressContainer}>
        <Grid item xs={12}>
            Loading Map Data
        </Grid>
        <Grid item xs={12}>
            <CircularProgress   className={styles.progress} />
        </Grid>
    </Grid>)
};

export default PollMapScreen;