import qs from "qs";

export class ParametersService {

    private static _inst: ParametersService;

    private _vendorKey: string;
    get vendorKey() {
        return this._vendorKey;
    }

    private _pollQuestionIds: number[];
    get pollIds() {
        return this._pollQuestionIds;
    }

    static parseParameters(params: string) {
        if (!this._inst) {
            this._inst = new ParametersService();
            const {key, p} = qs.parse(params, {ignoreQueryPrefix: true});
            this._inst._vendorKey = key;
            //this._inst._pollQuestionIds = Array.isArray(p) ? p.map(pollId => parseInt(pollId, 10)) : [parseInt(p, 10)];
        }
        return this._inst;
    }
}
