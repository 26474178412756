
import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    iconContainer: {
        height: (props:any) => props.iconSize,
    },
    icon: {
        width: (props:any) => props.iconSize,
        height: (props:any) => props.iconSize,
        borderRadius: (props: any) => props.radius,
    }
})

const IconObject = ({urlObject, width = 30, radius = 0}) => {
    const styles = useStyles({iconSize: width, radius});
    return (
        <div className={styles.iconContainer}>
            <img src={urlObject} className={styles.icon}/>
        </div>
    )
}

export default IconObject;