import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {getAppToken, getUserProfile, resetStorage} from "./services/TokenManager";
import {ParametersService} from "./services/ParametersService";
import {ThemeProvider} from "@material-ui/styles";
import {AppProvider} from "./context/AppContext";
import {PollProvider} from "./context/PollContext";

import { createMuiTheme } from '@material-ui/core/styles';
import { blue} from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#2962FF"
        },
        secondary: {
            main:"#5c6bc0"
        },
    },
});

// We should really access to local storage, etc. here..

// If the profile/token is already set, then we need to load accordingly...
let [token, profile] = [getAppToken(),getUserProfile()];
// if profile/token isn't set, we need to null out..
if (!token || !profile) {
    resetStorage()
    token = profile = null;
}
const params = ParametersService.parseParameters(window.location.search);

const vendorKey = params.vendorKey;

ReactDOM.render(
    <Router>
        <ThemeProvider theme={theme}>
            <AppProvider>
                <PollProvider>
                    <App token={token} userProfile={profile} vendorKey={vendorKey}/>
                </PollProvider>
            </AppProvider>
        </ThemeProvider>

    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
