import React from 'react';
import "./Poll.css"
import {PollChoiceModel} from "../../models/PollChoiceModel";

import {makeStyles, Typography} from "@material-ui/core";
import classNames from 'classnames';



const useStyles = makeStyles(theme => ({
    pollChoiceContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    pollChoice: {
        fontWeight: 500,
    }
}));

const PollChoice = ({choice, onSelect, selectedChoice} : {choice: PollChoiceModel, onSelect: Function, selectedChoice: PollChoiceModel}) => {

    const styles = useStyles({});
    const isSelectedChoice = selectedChoice !== null && choice.PollChoiceId === selectedChoice.PollChoiceId;

    const pollChoiceStyle = {
        //backgroundColor: isSelectedChoice ? 'blue' : choice.ChartColour ,
        backgroundColor: isSelectedChoice ? '#2962ff' : '#f1f1f1',
        color: isSelectedChoice ? 'black' : 'black'
    };

    const pollChoiceClasses = classNames({
        "poll-choice-container":true,
        "poll-choice-selected" : isSelectedChoice
    });

    const pollChoiceContainer = classNames('poll-choice', styles.pollChoiceContainer);
    return(
        <div className={pollChoiceClasses} style={pollChoiceStyle} onClick={() => onSelect(choice)}>
            <div className={pollChoiceContainer}>
                <Typography variant="body1" className={styles.pollChoice}>{choice.Choice}</Typography>
            </div>
        </div>
    );
}

export default PollChoice;
