import React, {useContext, useState} from "react";
import {
    Box,
    Dialog,
    Grid,
    IconButton, Link,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import PollCommentModel from "../../models/PollCommentModel";
import AvatarThumbnail from "../Images/AvatarThumbnail";
import {AvatarizeName} from "../../services/Utilities";
import lightning from "../../assets/images/lightning-xs.png";
import IconObject from "../Images/IconObject";
import PollReplyComment from "./PollReplyComment";
import CloseIcon from '@material-ui/icons/Close'
import {getPollCommentReplies} from "../../services/PollsService";
import {AppContext} from "../../context/AppContext";
import {PollContext} from "../../context/PollContext";
import PollComments from "./PollComments";
import moment from "moment";

const useStyles = makeStyles(() => ({
    commentName: {
        fontWeight:900.
    },
    muted : {
        fontWeight:300,
    },
    commentResponseContainer: {
        marginLeft:10,
        cursor:"pointer",
    }
}))

const PollComment = ({comment, ignoreReplyTo, small, onReload = () =>{}} : {comment:PollCommentModel, ignoreReplyTo: boolean, small: boolean, onReload: Function}) => {
    const styles = useStyles({});
    const [openReplyToComment, setOpen] = useState(false);
    const {token} = useContext(AppContext);
    const {currentPoll} = useContext(PollContext);

    const [replyComments, setReplyComments] = useState(null);

    const showReplies = async (commentId) => {
        const comments = await getPollCommentReplies(token, currentPoll.PollId, commentId);
        setReplyComments(comments);
    }

    const respondToComment = () => {
        setOpen(true);
    }

    const handleClose = (reload = false) => {
        setOpen(false);
        if (reload) {
            onReload();
        }
    }

    const humanize = (time) => {
        const commentTime = moment(time);
        const now = moment();

        return moment.duration(now.diff(commentTime)).humanize();
    }


    return (
        <div>
        <Grid container>
            <Grid item xs={1}>
                <AvatarThumbnail url={comment.AvatarMicrothumbUrl} alt={AvatarizeName(comment.DisplayName)} width={30} radius={30}/>
            </Grid>
            <Grid item xs={9}>
                <Typography variant={small ? "h6" : "h5"} align="left" className={styles.commentName}>
                    {comment.DisplayName}
                </Typography>
                <Grid item container xs>
                    <Grid item>
                        <Typography variant={small ? "subtitle1" : "body2"} display="block" align="left" className={styles.muted}>
                            {comment.CommentText}
                        </Typography>
                    </Grid>
                    {ignoreReplyTo ? null :
                        <Grid item className={styles.commentResponseContainer} onClick={() => respondToComment()}>
                            <IconObject urlObject={lightning} width={20}/>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>

            {comment.CommentCount === 0 || replyComments !== null ? null :
                <Box textAlign="left" ml={4}>
                    <Link component="button" variant="body2" onClick={() => showReplies(comment.CommentId)}>
                            See Replies
                    </Link>
                </Box>
            }
            {replyComments && replyComments.length > 0 ?
                <div>
                    <PollComments comments={replyComments} fullScreen={false} inset={40} ignoreReplyTo={true} small={true} reload={null}/>
                    <Box textAlign="left" ml={4}>
                        <Link component="button" variant="body2" onClick={() => setReplyComments(null)}>
                            Hide Replies
                        </Link>
                    </Box>
                </div>
                : null}
        <Dialog open={openReplyToComment} fullWidth={true}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => handleClose()}>
                    <CloseIcon/>
                </IconButton>
                <Typography variant="h6">
                    Reply to {comment.DisplayName}
                </Typography>
            </Toolbar>
            <PollReplyComment comment={comment} onSave={handleClose}/>
        </Dialog>
        </div>
    )
};

export default PollComment;